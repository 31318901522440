import CandiceTan from "../images/about/team/Candice_Tan.webp";
import JessicaLi from "../images/about/team/Jessica_Li.webp";
import MatthewY from "../images/about/team/Matthew_Y.webp";
import MaxwellFu from "../images/about/team/Maxwell_Fu.webp";
import NicholasChin from "../images/about/team/Nicholas_Chin.webp";
import TessieXiao from "../images/about/team/Tessie_Xiao.webp";
import XLDeng from "../images/about/team/XL_Deng.webp";

const enUs = {
  header: {
    button: "Get in Touch",
    menu: [
      {
        title: "Home",
        href: "/",
      },
      {
        title: "Custody",
        href: "/custody",
      },
      {
        title: "Coins",
        href: "/coins",
      },
      {
        title: "News",
        href: "/news",
      },
      {
        title: "Company",
        children: [
          {
            title: "About Us",
            href: "/about-us",
          },
          {
            title: "Join Us",
            href: "/join-us",
          },
        ],
      },
    ],
  },
  footer: {
    h1: "Trusted and Proven",
    h2: ["Digital", "Asset", "Custody", "Solutions"],
    p: "Institutional-grade, regulated digital asset custodian serving clients globally.",
    copyright: "Copyright 2024 © BiT Global.  All rights reserved.",
    policy: "Privacy Policy",
    forkPolicy: "Fork Policy",
    terms: "Terms of Use",
    email: "support@bitgc.io",
    menu: [
      {
        title: "Products",
        children: [
          {
            title: "Custody",
            href: "/custody",
          },
        ],
      },
      {
        title: "Coins",
        children: [
          {
            title: "Coins",
            href: "/coins",
          },
        ],
      },
      {
        title: "Company",
        children: [
          {
            title: "About Us",
            href: "/about-us",
          },
          {
            title: "News",
            href: "/news",
          },
          {
            title: "Join Us",
            href: "/join-us",
          },
        ],
      },
    ],
  },
  notFound: {
    h1: "Sorry, Page Is Not Found!",
    p: "We're sorry, The page you requested could not be found, Please go back to the homepage.",
    button: "GO HOME",
  },
  home: {
    disclaimer: {
      h3: "Institutional Investor Disclaimer",
      p: "The products and services offered by BiT Global Custody and its affiliates are exclusively available to institutional investors, including accredited or professional investors, in accordance with applicable law and regulatory requirements. These products and services are not intended for the general public or for retail investors. By accessing this site and engaging with BiT Global Custody or its affiliates for their products and services, you confirm that you qualify as an institutional investor and are not a member of the general public nor are you operating in the capacity of a retail investor.",
      button: "Accept and Enter",
    },
    banner: {
      h1: ["Trusted and Proven", "Institutional Custody Solutions"],
      p: "BiT Global is a regulated digital asset custodian serving clients globally since 2020.",
      button: "Get in Touch",
    },
    section1: {
      h2: ["Top Digital Assets", "Supported"],
      more: "More",
      p: "We are constantly adding new tokens to improve our accessibility and diversity. Get in touch for a complete list of tokens under our administration.",
    },
    section2: {
      h2: "Why BiT Global",
      p: "The Gold Standard Digital Asset Custody",
      content: [
        {
          h3: "Security",
          p: "Security is our top priority. We combine cold and hot multi-signature wallet technology to eliminate single point of compromise. Store, access and transfer digital assets on a trusted and regulated platform.",
        },
        {
          h3: "Flexibility",
          p: "Our solutions can be customized to address your business needs, risk profiles and regulatory obligations.",
        },
        {
          h3: "Service",
          p: "We provide 24/7 services to our global clients including fast on-boarding, secure smart contract management and seamless multi-chain transactions.",
        },
      ],
    },
    section3: {
      h2: "Custody",
      p: "Safeguard digital assets with our industry leading and regulated custody solutions that provides the flexibility and security for our clients.",
      button: "Learn More",
    },
    section4: {
      h2: "Who we serve",
      content: [
        {
          h3: "Exchanges",
          p: "By leveraging BiT Global's secure and flexible vault technology to safeguard their digital assets, exchanges can focus on enhancing the speed and scalability of their digital assets operations.",
        },
        {
          h3: "Crypto VC Funds",
          p: "Meet fiduciary needs with our regulated custody solutions without compromising on speed and operational efficiency. Our ability to integrate with emerging blockchain technology and provide broad token support ensures we meet the evolving needs of crypto VC funds.",
        },
        {
          h3: "Family Offices",
          p: "With a focus on compliance and cutting edge technology, BiT Global supports family offices worldwide in effectively managing and growing their wealth with innovative and trusted custody solutions.",
        },
        {
          h3: "Asset Managers",
          p: "BiT Global offers professional digital asset custody and management solutions for asset management companies. By ensuring security, compliance, and efficiency, our tailored services allow for effective management of diversified portfolios.",
        },
      ],
    },
    section5: {
      p: "BiT Global specializes in providing secure custody solutions with a global reach that's purpose-built for institutional clients.",
    },
    section6: {
      h2: "contact us",
      p: "Safeguard your digital assets with BiT Global's custody solutions.",
      form: {
        fields: {
          name: "Name",
          email: "Business Email",
          company: "Company Name",
          message: "Inquiry",
        },
        validate: {
          required: "Please input the field.",
          email: "Please enter a valid email address.",
          purify: "Please input valid character.",
          maxLength:
            "The input cannot exceed a maximum of {maxLength} characters.",
        },
        message: {
          success: "Submit successfully.",
        },
      },
    },
  },
  custody: {
    banner: {
      h1: "Custody",
      p: "Solutions to meet your digital asset needs-configure permissions, policies, approval workflows, whitelists and more.",
      button: "Get in Touch",
    },
    section1: {
      h2: "What is BiT Global Custody Solutions",
      p: "We offer the leading institutional-grade hot and cold wallet solutions with the utmost security while optimizing for asset liquidity and broad token support. Whether your goals are long-term asset management or DeFi services such as staking or trading, our solutions will meet your needs.",
    },
    section2: {
      h2: "Our Features",
      content: [
        {
          h3: "Class III Bank Vaults",
          p: "Our cold storage vaults are reinforced by bank-grade security.",
        },
        {
          h3: "Governance and Policies",
          p: "We offer configurable permissions, policies, approval workflows, whitelists and more.",
        },
        {
          h3: "Transaction Performance Management",
          p: "Set limits and transaction volume so you can scale.",
        },
        {
          h3: "24/7 Withdrawal Request",
          p: "Our dedicated team is reachable 24/7 to support our global clients.",
        },
      ],
    },
    section3: {
      h2: "How are the Keys Securely Controlled?",
      p: "BiT Global holds all three keys for the Vaults. The User Key and Backup Key are kept in cold storage isolated from the internet. The Platform Key is kept on BitGo's HSMs. Stringent security checks are implemented on every initiated fund transfer.",
      svg: [
        {
          title: "User Key",
          content: [
            "Generated and stored offline by",
            "BiT Global.",
            "Used to initiate transactions.",
          ],
        },
        {
          title: "Backup Key",
          content: [
            "Generated and stored offline by",
            "BiT Global.",
            "Used for disaster recovery.",
          ],
        },
        {
          title: "Platform Key",
          content: [
            "Generated and stored on",
            "BitGo HSMs.",
            "Used to countersign transactions.",
          ],
        },
      ],
    },
    section4: {
      h2: "Why choose BiT Global Custody Solutions",
      content: [
        {
          h3: "Institutional-grade Custody",
          p: "Our assets are shielded within bank-grade vaults, bolstered by robust security processes and adaptable wallet policies.",
        },
        {
          h3: "Battle-tested Security",
          p: "Benefit from industry-leading security technology, ensuring the highest level of asset safeguarding.",
        },
        {
          h3: "Comprehensive Solutions",
          p: "Integrate both hot and cold wallet technologies for a balanced approach, ensuring enhanced liquidity and security. Utilize hot wallets for efficient access to DeFi and staking, maximizing the productivity of your funds.",
        },
        {
          h3: "Offline Storage Assurance",
          p: "Mitigate risks with our assets stored on non-internet-accessible machines, safeguarding against potential hacker threats.",
        },
      ],
    },
  },
  about: {
    banner: {
      h1: "About Us",
      p: "Our dedicated team brings together a wealth of expertise across traditional finance, crypto technology and IT firms.",
    },
    section1: {
      p: [
        "BiT Global specializes in providing digital asset custody services with a global reach. Our seasoned team of professionals brings together a wealth of expertise across traditional finance, crypto technology and IT platforms. This diverse background empowers us to navigate the complexities of the evolving digital financial landscape with foresight and adaptability.",
        "BiT Global takes pride in its strategic leadership that straddles global finance and cutting edge technology under the strategic guidance of Mr. XL Deng, a seasoned financial institution executive. Previously, Mr. Deng led the China business of Paulson Investment Management Co., a leading global hedge fund famed for its successful bet against the subprime securities in 2008. Prior to Paulson, Mr. Deng headed Morgan Stanley's Alternative Investment Partners business in Asia. Collectively, the team draws on decades of experience at leading financial institutions on a global basis.",
        "We collaborate closely with leading global blockchain platforms including BitGo, one of our strategic partners, and strive to deliver innovative technological solutions meticulously crafted to ensure the robust deployment and protection of your digital assets. Our solutions are tailored to meet the unique dynamics of the rapidly-evolving digital asset landscape into the future.",
      ],
    },
    section2: {
      h2: "Our Vision",
      p: "We pride ourselves on delivering innovative services meticulously crafted to withstand the challenges of the future. With a forward-thinking approach and a commitment to professionalism, we ensure the robust protection and security of your digital assets. Our solutions are tailored to meet the unique dynamics of the rapidly-evolving digital ecosystem, providing you with flexibility and security in the ever-changing financial landscape.",
    },
    section3: {
      h2: "Our team",
      p: "BiT Global's team consists of seasoned professionals with extensive experience in the digital asset custody and financial services industries. We combine deep industry knowledge with a forward-thinking approach to achieve protection and security in the rapidly-evolving blockchain landscape.",
      introduction: "Meet Our Leadership Team",
      content: [
        {
          icon: MaxwellFu,
          name: "Maxwell Fu",
          position: "Chief Executive Officer",
          description:
            "From his successful background as a blockchain native and frontier technologist, Maxwell leads a professional team with diversified financial and technical background to provide innovative digital asset custody solutions for the Web3 economy.",
        },
        {
          icon: JessicaLi,
          name: "Jessica Li",
          position: "Chief Revenue Officer",
          description:
            "As a successful investor with decades of experience, Jessica brings extensive expertise and business insight from innovative industries including blockchain and digital assets.",
        },
        {
          icon: CandiceTan,
          name: "Candice Tan",
          position: "Chief Financial Officer",
          description:
            "Candice leads BiT Global's finance team, overseeing its financial and accounting functions across multiple team locations. She brings over a decade's relevant industry experience, and has developed expertise in executing dynamic financial management to align with organizational goals.",
        },
        {
          icon: MatthewY,
          name: "Matt Yeung",
          position: "Chief Compliance Officer",
          description:
            "With a rich combo of compliance, risk and auditing experience for over a decade, Matt previously worked as head of compliance for a leading Asia fintech firm and held auditing roles at EY and Deloitte.",
        },
        {
          icon: NicholasChin,
          name: "Nicholas Chin",
          position: "VP of Engineering",
          description:
            "Nicholas brings his solid experience in technology and blockchain into BiT Global, and with his various experiences in blockchain-based systems design, he leads the team on security infrastructure's management that ensures protection against all potential threats.",
        },
        {
          icon: TessieXiao,
          name: "Tessie Xiao",
          position: "VP of Business Development",
          description:
            "Demonstrated with years of web3 experience, Tessie adds value to the team with a strong understanding of Public Chains, DeFi, and other related ecosystems, and plays a key role in driving the company's growth and partnerships.",
        },
      ],
    },
  },
  coins: {
    banner: {
      h1: "Coins",
      p: "Tokens supported, and more to be added advancing towards industry-wide coverage.",
    },
    section1: {
      h2: "Supported Coins",
      p: "To request for new coin and token support, please email BiT Global's customer support team.",
    },
  },
  privacyPolicy: {
    banner: {
      h1: "Privacy Policy",
      p: "Last updated: 7 August 2024",
    },
  },
  termsOfUse: {
    banner: {
      h1: "Terms of Use",
      p: "Last updated: 7 August 2024",
    },
  },
  forkPolicy: {
    banner: {
      h1: "Fork Policy",
      p: "Last updated: 26 November 2024",
    },
  },
  newsroom: {
    banner: {
      h1: "Newsroom",
      p: " ",
    },
    section1: {
      h2: "In the news",
      p: "Explore the Latest Press Coverage on BiT Global",
      content: [
        {
          h3: "BiT Global and BitGo to form multi-jurisdictional custody JV",
          p: "Aug 9, 2024",
          link: "https://blog.bitgo.com/bitgo-to-move-wbtc-to-multi-jurisdictional-custody-to-accelerate-global-expansion-plan-2ea0623fa2c8",
        },
        {
          h3: "$1 billion lawsuit against Coinbase over wBTC delisting filed by Kneupper & Covey",
          p: "Dec 13, 2024",
          link: "https://kneuppercovey.com/1-billion-lawsuit-against-coinbase-over-wbtc-delisting-filed-by-kneupper-covey",
        },
      ],
    },
  },
  joinUs: {
    banner: {
      h1: "Join us",
      p: [
        "Are you ready to be at the forefront of blockchain innovation?",
        "Become part of our BiT Global team that provides the leading institutional-grade wallet and custody solutions for businesses around the world.",
      ],
    },
    section1: {
      h2: "Our Values",
      content: ["Innovation and Trust", "Customer-First", "Compliance"],
    },
    section2: {
      h2: "Jobs",
      p: "We encourage unsolicited applications. If you don't find a matching job post, contact us to share your profile and interest with BiT Global. We'll reach out to you.",
      view: "View job",
      content: [
        {
          title: "Senior Product Designer",
          desc: "Full-time",
          url: "/career?job=senior_product_designer",
        },
        {
          title: "KYC Operations",
          desc: "Full-time",
          url: "/career?job=kyc_operations",
        },
        {
          title: "Accounting Manager",
          desc: "Full-time",
          url: "/career?job=accounting_manager",
        },
        {
          title: "Backend Engineer",
          desc: "Full-time",
          url: "/career?job=backend_engineer",
        },
        {
          title: "Front-end Engineer",
          desc: "Full-time",
          url: "/career?job=frontend_engineer",
        },
        {
          title: "Support Engineer",
          desc: "Full-time",
          url: "/career?job=support_engineer",
        },
      ],
    },
  },
  career: {
    section1: {
      h2: "Apply for this Job",
      required: "Required",
      p: "Founded in 2020, BiT Global is a digital financial security custody solutions provider. Our innovative services are specifically designed to withstand the challenges of the future.",
      form: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phone: "Phone",
        resume: "Resume/CV",
        cover: "Cover Letter",
        attach: "Attach",
        or: "or",
        enter: "Enter manually",
        fileTypes: "(File types: pdf, doc, docx, txt, rtf)",
        linkedIn: "LinkedIn Profile",
        website: "Website",
        submit: "Submit Application",
        success: "Success",
      },
      jobs: {
        support_engineer: {
          title: "Support Engineer",
          description:
            "Founded in 2020, BiT Global is a digital financial security custody solutions provider. Our innovative services are specifically designed to withstand the challenges of the future.",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Responsibilities",
              content: [
                "Provide real-time support, utilizing Slack for remote troubleshooting, ensuring BiT Global's technical platform receives excellent technical assistance.",
                "Collaborate with the senior support team during outages, actively participating in war room calls to maintain the normal operation of the system.",
                "Handle issues responsibly, utilizing all available resources to drive issue resolution, and escalate to the senior support team when necessary.",
                "Adopt and adhere to business processes and policies to ensure efficient and standardized work.",
                "As the frontline support team, exhibit a professional attitude in handling customer queries and escalations, ensuring meticulous case management and documentation.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Minimum of 1-2 years of experience as a technical support engineer or a similar role.",
                "Ability to work under high pressure and effectively troubleshoot.",
                "Flexibility to work unorthodox hours when needed, demonstrating professional ethics and capabilities.",
                "Experience in API implementation and debugging.",
                "Excellent verbal and written communication skills, coupled with outstanding troubleshooting and analytical abilities.",
              ],
            },
          ],
        },
        frontend_engineer: {
          title: "Front-end Engineer",
          description:
            "Founded in 2020, BiT Global is a digital financial security custody solutions provider. Our innovative services are specifically designed to withstand the challenges of the future.",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Roles and Responsibilities",
              content: [
                "Work with Backend Engineers, Blockchain Engineers, Product Managers, UI Designers, QA Engineers, and DevOps Engineers to own your solution from development to production.",
                "Follow the company's coding standards and take responsibility for coding implementation. This includes participating in product front-end performance optimization, troubleshooting and resolution, and security reinforcement.",
                "While participating in module design, undertake system and project development work. Share technology insights to jointly promote the progress of team members.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Possess over 5 years of experience in web application design and development.",
                "Hold a Bachelor's Degree in Computer Science or a related field.",
                "Demonstrate a thorough understanding of core design principles and common design patterns of React.",
                "Be proficient and possess rich experience in using Hardhat and Ethers to debug and develop projects, with familiarity in related dependencies.",
                "Take responsibility for writing product core module code and contributing to system reconstruction.",
                "Have experience with Node.js (express/koa), GraphQL, Next.js, and a willingness to work full-stack, which is considered a significant advantage.",
              ],
            },
          ],
        },
        senior_product_designer: {
          title: "Senior Product Designer",
          description: "",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Roles and Responsibilities",
              content: [
                "Spearheaded digital product design initiatives from initial research through conceptualization to final launch.",
                "Engage in every phase of the design process, including research, ideation, design execution, and user testing.",
                "Articulate user needs through the creation of personas, journey maps, user flows, wireframes, and prototypes.",
                "Organize and facilitate workshops, design critiques, user interviews, and internal knowledge-sharing sessions.",
                "Collaborate with cross-functional teams to champion design excellence and innovation.",
                "Continuously maintain and enhance design toolkits, systems, and brand assets.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Over 5 years of experience in designing web and mobile applications.",
                "Profound understanding of the product design lifecycle, design thinking principles, tools, and methodologies.",
                "Ability to foster user empathy and transform business insights into aesthetically pleasing and intuitive designs.",
                "Humble yet capable of justifying design choices with data, a strong design philosophy, and a creative approach.",
                "Skillful in visualizing complex data and user processes through clear diagrams and elegant interfaces.",
                "Proficient in layout, iconography, typography, and information architecture.",
                "Enthusiastic about exploring innovative design methods, processes, tools, and emerging technologies.",
                "A portfolio that highlights case studies and the design process behind your solutions.",
              ],
            },
          ],
        },
        kyc_operations: {
          title: "KYC Operations",
          description: "",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Roles and Responsibilities",
              content: [
                "Possess experience conducting due diligence reviews for institutional clients, including investment managers, funds, money service businesses, banks, and operating companies.",
                "Demonstrated capability to compile AML and non-AML due diligence using various public research methods.",
                "Experience in managing and facilitating cross-team projects.",
                "Exceptional attention to detail.",
                "Worked in a client-facing role, offering support and guidance to clients or middle office teams.",
                "Ability to identify and resolve problems or navigate obstacles effectively.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Conduct client KYC reviews independently, adhering to established policies and procedures.",
                "Acquire in-depth knowledge of institutional KYC requirements for both traditional and crypto-native clients.",
                "Manage the entire process from start to finish, identifying gaps and overcoming obstacles efficiently.",
                "Handle competing priorities and projects while meeting strict deadlines.",
                "Collaborate with team members and stakeholders to prioritize tasks and track progress.",
                "Deliver KYC reviews promptly.",
                "Observe and learn from leadership and colleagues to understand how processes align with broader objectives.",
                "Actively participate in cross-team calls and initiatives.",
              ],
            },
          ],
        },
        accounting_manager: {
          title: "Accounting Manager",
          description: "",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Roles and Responsibilities",
              content: [
                "Participate in system implementation projects to enhance operational efficiency.Contribute to ad-hoc projects and respond to management inquiries as needed.",
                "Review monthly reporting packages and reconciliations prepared by accounting staff; prepare and record necessary intercompany adjustment entries and reconcile all intercompany balances.",
                "Assist in global payroll processes, ensuring timely and precise payroll execution.",
                "Ensure accurate revenue recognition and appropriate allocation of revenue streams.",
                "Provide support for annual audits by preparing and reviewing necessary reports and documentation.",
                "Actively engage in the monthly, quarterly, and year-end closing processes, including the preparation of journal entries, maintenance of account reconciliations, and compliance reporting.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Strong communication and interpersonal skills.",
                "Proficient in Microsoft Excel, Word, and Google Sheets.",
                "At least 5 years of experience in corporate accounting.",
                "CPA certification is preferred.",
                "Bachelor's degree in Accounting or Finance.",
              ],
            },
          ],
        },
        backend_engineer: {
          title: "Backend Engineer",
          description: "",
          type: "Full-time",
          content: [
            {
              label: "Job Type",
              content: "Full-time",
            },
            {
              label: "Roles and Responsibilities",
              content: [
                "Design and optimize project architecture and back-end systems, ensuring stability and performance.",
                "Oversee functional iterations and performance enhancements for wallet and financial products.",
                "Collaborate with product, operations, and other teams to design technical solutions and evaluate development timelines.",
                "Stay updated on technical trends in the blockchain field and apply relevant advancements to research and development initiatives.",
              ],
            },
            {
              label: "Requirements",
              content: [
                "Bachelor's degree or higher in Computer Science or a related field.",
                "Strong coding skills with familiarity with common algorithms and data structures.",
                "Proficient in at least one programming language: Python, Golang, or Java.",
                "Experience with Linux-based development environments; familiarity with MySQL and Redis, including their fundamental principles.",
                "Maintain high standards for personal and team code quality; demonstrate a consistent practice of code reviews.",
              ],
            },
          ],
        },
      },
    },
  },
};

export default enUs;
